import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as customerSupportApi from '../../apis/customer-support-api';
import ICustomerData, {
  ICustomerAudit,
} from "../../models/customer-data.interface";
import { RootState } from '../store';
export type SearchType = 'EMAIL' | 'ID' | 'BOOKING';

import { addTripsCases, fetchCustomerTrips, selectTrips, tripsReducers } from './customer-trips';
import { addBookingCases, cancelCustomerBooking, fetchCustomerBookings, bookingReducers } from './customer-bookings';

import { initialCustomerState } from './customer-state';



export const fetchCustomerDataByIdentifier = createAsyncThunk<
  ICustomerData,
  boolean | undefined,
  { state: RootState }
>('customer/fetchCustomerDataByIdentifier', async (_, { dispatch, getState }) => {
  const identifier = getState().customer.search.identifier.value;
  let customer = null;
  if (getState().customer.search.type === 'ID') {
    customer = await customerSupportApi.getCustomerById(identifier);
  } else if (getState().customer.search.type === 'EMAIL') {
    const response = await customerSupportApi.getCustomerByEmail(identifier)
    customer = response[0];
  }

  customer.idVerificationSessions = await customerSupportApi.getCustomerIdVerificationSessions(customer.id);
  return customer;
},
  {
    condition: (refresh, { getState }) => {
      // execute the function if it's explicitly requested
      if (refresh) {
        return true;
      }

      const { customer } = getState();
      customer.error = null;
      return customer.status === 'idle'; // execute the fetch function only when the status is idle
    },
  }
);

export const fetchCustomerDataById = createAsyncThunk<
  ICustomerData,
  { id: string },
  { state: RootState }
>('customer/fetchCustomerDataById', async ({ id }, { dispatch, getState }) => {
  const customer = await customerSupportApi.getCustomerById(id);
  customer.idVerificationSessions = await customerSupportApi.getCustomerIdVerificationSessions(id);

  return customer;
},
  {
    condition: (refresh, { getState }) => {
      // execute the function if it's explicitly requested
      if (refresh) {
        return true;
      }

      const { customer } = getState();
      customer.error = null;
      customer.errorDialog = null;
      return customer.status === 'idle'; // execute the fetch function only when the status is idle
    },
  }
);

export const fetchCustomerAuditTrail = createAsyncThunk<
  ICustomerAudit,
  { id: string },
  { state: RootState }
>('customer/fetchCustomerAuditTrail', async ({ id }, { dispatch, getState }) => {
  const pageSize = 50;
  const result: ICustomerAudit = await customerSupportApi.getCustomerAuditTrail(id, pageSize);
  while (result?.meta?.cursor) {
    const cursor = result?.meta?.cursor
    const response = await customerSupportApi.getCustomerAuditTrail(id, 10, cursor);
    result.data = result.data.concat(response.data);
    result.meta.cursor = response.meta.cursor;
  }
  return result;
},
  {
    condition: (refresh, { getState }) => {
      // execute the function if it's explicitly requested
      if (refresh) {
        return true;
      }

      const { customer } = getState();
      customer.error = null;
      return customer.auditTrail.status === 'idle'; // execute the fetch function only when the status is idle
    },
  }
);

export const markCustomerForDeletion = createAsyncThunk<
  string,
  { id: string },
  { state: RootState }
>(
  'customer/deleteCustomerAccount',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { customer } = getState();
      return await customerSupportApi.markCustomerForDeletion(customer.id);

    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const unmarkCustomerForDeletion = createAsyncThunk<
  ICustomerData,
  { id: string },
  { state: RootState }
>(
  'customer/undeleteCustomerAccount',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { customer } = getState();
      await customerSupportApi.unmarkCustomerForDeletion(customer.id);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const resetIdVerification = createAsyncThunk<
  ICustomerData,
  { id: string },
  { state: RootState }
>(
  'customer/resetIdVerification',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { customer } = getState();
      await customerSupportApi.resetIdVerification(customer.id);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const suspendCustomer = createAsyncThunk<
  string,
  { id: string },
  { state: RootState }
>(
  'customer/suspendCustomer',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { customer } = getState();
      return await customerSupportApi.suspendCustomer(customer.id);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const unsuspendCustomer = createAsyncThunk<
  ICustomerData,
  { id: string },
  { state: RootState }
>(
  'customer/unsuspendCustomer',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { customer } = getState();
      await customerSupportApi.unsuspendCustomer(customer.id);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const customerSlice = createSlice({
  name: 'customer',
  initialState: initialCustomerState,
  reducers: {
    setSearchTerm(state, action) {
     if (!action.payload) {
       state.search.identifier = undefined;
       state.search.type = undefined;
     } else {
       const searchTerm = action.payload.toLowerCase();
       state.search.identifier = {
         value: searchTerm
       };
       state.search.type = /^[0-9A-Fa-f]{8}(-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$/.test(searchTerm) ? 'ID' : 'EMAIL'
     }
    },
    setBookingSearchId(state, action) {
      if (action.payload === undefined){
        state.search.identifier = undefined;
        state.search.type = undefined;
      } else {
        if (!state.search.identifier) {
          state.search.identifier = { bookingId: action.payload };
        } else {
          state.search.identifier.bookingId = action.payload;
        }
      }
    },
    setBookingSearchUserInput(state, action) {
      if (!action.payload){
        state.search.identifier = undefined;
        state.search.type = undefined;
      } else {
        if (!state.search.identifier) {
          state.search.identifier = { userInput: action.payload };
        } else {
          state.search.identifier.userInput = action.payload
        }
      }
    },
    setSearchType(state, action) {
      if (!action.payload){
        state.search.identifier = undefined;
        state.search.type = undefined;
      } else {
          state.search.type = action.payload.type;
      }
    },
    resetError(state, action) {
      state.error = action.payload;
    },
    resetErrorDialog(state, action) {
      state.errorDialog = action.payload;
    },
    setErrorDialog(state, action) {
      state.errorDialog = {
        code: action.payload,
        title: action.payload.title,
        message: action.payload.message,
      }
    },
    resetCustomer(state, action) {
      state.customer = undefined;
      state.id = undefined;
      state.auditTrail.data = [];
      state.auditTrail.filter.auditChangedBy.customer = false;
      state.auditTrail.filter.auditChangedBy.support = false;
      state.bookings.data = [];
      state.trips.data = [];
    },
    setAuditTrailFilterCustomerValue(state, action) {
      state.auditTrail.filter.auditChangedBy.customer = action.payload;
      state.auditTrail.data = state.auditTrail.orgData.filter(item =>
        item.auditChangedBy.startsWith('ChangedByCustomer') === action.payload ||
        !item.auditChangedBy.startsWith('ChangedByCustomer') === state.auditTrail.filter.auditChangedBy.support)
    },
    setAuditTrailFilterSupportValue(state, action) {
      state.auditTrail.filter.auditChangedBy.support = action.payload;
      state.auditTrail.data = state.auditTrail.orgData.filter(item =>
        !item.auditChangedBy.startsWith('ChangedByCustomer') === action.payload ||
        item.auditChangedBy.startsWith('ChangedByCustomer') === state.auditTrail.filter.auditChangedBy.customer)
    },
    ...tripsReducers,
    ...bookingReducers
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCustomerDataByIdentifier.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
        state.errorDialog = null;
      })
      .addCase(fetchCustomerDataByIdentifier.fulfilled, (state, action) => {
        //state.customer = state.customer = action.payload[0];
        if (!action.payload || !action.payload || !action.payload.id) {
          state.status = 'failed';
          state.error = state.search.type === 'ID' ? 'ID_NOT_FOUND' : 'EMAIL_NOT_FOUND';
          state.id = undefined;
        } else {
          state.status = 'succeeded';
          state.error = null;
          state.errorDialog = null;
          state.customer = state.customer = action.payload;
          state.id = action.payload.id;
        }
      })
      .addCase(fetchCustomerDataByIdentifier.rejected, (state, action) => {
        if (action.error.message === 'FORBIDDEN') {
          state.error = 'FORBIDDEN';
        } else {
          state.error = action.error.message;
        }
        state.status = 'failed';
        state.id = undefined;
      })

      .addCase(fetchCustomerDataById.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
        state.errorDialog = null;
      })
      .addCase(fetchCustomerDataById.fulfilled, (state, action) => {
        //state.customer = state.customer = action.payload[0];
        if (!action.payload || !action.payload || !action.payload.id) {
          state.status = 'failed';
          state.error = 'USER_NOT_FOUND';
          state.id = undefined;
        } else {
          state.status = 'succeeded';
          state.error = null;
          state.errorDialog = null;
          state.customer = state.customer = action.payload;
          state.id = action.payload.id;
        }
      })
      .addCase(fetchCustomerDataById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.id = undefined;
      })
      .addCase(markCustomerForDeletion.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
        state.errorDialog = null;
      })
      .addCase(markCustomerForDeletion.fulfilled, (state, action) => {
        if (!action.payload) {
          state.status = 'succeeded';
          state.error = null;
          state.errorDialog = null;
          state.customer = undefined;
          state.id = undefined;
        } else {
          state.status = 'failed';
          state.error = action.payload;
          if (action.payload === 'MAIL_SEND_ERROR') {
            state.errorDialog = {
              code: action.payload,
              title: 'Unable to send email to Customer',
              message: 'The deletion email could not send to the customer.',
            }
          } else {
            state.errorDialog = {
              code: action.payload,
              title: 'Unable to send SUSPEND to Customer',
              message: 'The Customer could not be SUSPENDED because of: ' + action.payload,
            }
          }
        }
      })
      .addCase(markCustomerForDeletion.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(unmarkCustomerForDeletion.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(unmarkCustomerForDeletion.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
        state.errorDialog = null;
      })
      .addCase(unmarkCustomerForDeletion.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.errorDialog = null;
        state.customer = undefined;
        state.id = undefined;
      })
      .addCase(resetIdVerification.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
        state.errorDialog = null;
      })
      .addCase(resetIdVerification.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.errorDialog = null;
        state.customer = undefined;
        state.id = undefined;
      })
      .addCase(resetIdVerification.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(suspendCustomer.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
        state.errorDialog = null;
      })
      .addCase(suspendCustomer.fulfilled, (state, action) => {
        if (action.payload === '') {
          state.status = 'succeeded';
          state.error = null;
          state.errorDialog = null;
          state.customer = undefined;
          state.id = undefined;
        } else {
          state.status = 'failed';
          state.error = 'CUSTOMER_IS_IN_ACTIVE_TRIP';
          state.errorDialog = {
            code: 'CUSTOMER_IS_IN_ACTIVE_TRIP',
            title: 'Unable to Suspend Customer',
            message: 'The customer can not be suspended, because the customer is in an active trip.'
          };
        }
      })
      .addCase(suspendCustomer.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(unsuspendCustomer.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
        state.errorDialog = null;
      })
      .addCase(unsuspendCustomer.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.errorDialog = null;
        state.customer = undefined;
        state.id = undefined;
      })
      .addCase(unsuspendCustomer.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchCustomerAuditTrail.pending, (state, action) => {
        state.auditTrail.status = 'loading';
        state.error = null;
        state.errorDialog = null;
        state.errorDialog = null;
      })
      .addCase(fetchCustomerAuditTrail.fulfilled, (state, action) => {
        state.error = null;
        state.errorDialog = null;
        state.auditTrail.orgData = action.payload.data;
        state.auditTrail.data = action.payload.data;
        state.auditTrail.status = 'succeeded';
      })
      .addCase(fetchCustomerAuditTrail.rejected, (state, action) => {
        state.error = action.error.message;
        state.id = undefined;
        state.auditTrail.status = 'failed';
      })

    addTripsCases(builder);
    addBookingCases(builder);
  },
});

export default customerSlice.reducer;


// Actions
export const { setSearchTerm } = customerSlice.actions;
export const { setBookingSearchId } = customerSlice.actions;
export const { setBookingSearchUserInput } = customerSlice.actions;
export const { setSearchType } = customerSlice.actions;
export const { resetError } = customerSlice.actions;
export const { resetErrorDialog } = customerSlice.actions;
export const { setErrorDialog } = customerSlice.actions;
export const { resetCustomer } = customerSlice.actions;
export const { setAuditTrailFilterCustomerValue } = customerSlice.actions;
export const { setAuditTrailFilterSupportValue } = customerSlice.actions;

export const selectEmail = () => (state: RootState) => {
  return state.customer.search.identifier.value;
};
export const selectCustomer = () => (state: RootState) => {
  return state.customer;
};

export const selectAuditTrail = () => (state: RootState) => {
  return state.customer.auditTrail;
};

export const selectBookings = () => (state: RootState) => {
  return state.customer.bookings;
};


export const selectId = () => (state: RootState) => {
  return state.customer.id;
};


const { setTripsDateRageFilter, setTripsPaymentState } = customerSlice.actions
export {
  fetchCustomerTrips, selectTrips, setTripsDateRageFilter, setTripsPaymentState
};

export {
  cancelCustomerBooking, fetchCustomerBookings,
}
