import React from 'react';
import {Form, FormField, FormFieldLabel, Input} from '@vwfs-bronson/bronson-react'
import './style.css';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {
  selectCustomer,
  resetError,
  setBookingSearchId, setBookingSearchUserInput, setSearchType
} from '../../redux/slices/customer-slice';
import {Navigate} from 'react-router-dom';
import {fetchBookingDataBySearch} from "../../redux/slices/customer-bookings";

export default function SearchBookingView() {
  const dispatch = useAppDispatch();
  const [bookingValue, setBookingValue] = React.useState('');
  const [userValue, setUserValue] = React.useState('');
  const customer = useAppSelector(selectCustomer());

  const handleChangeId = (e: any) => {
    dispatch(resetError(undefined));
    const bookingIdValue = e.target.value;
    setBookingValue(bookingIdValue)
    dispatch(setBookingSearchId(bookingIdValue));
  }

  const handleChangeUserInput = (e: any) => {
    dispatch(resetError(undefined));
    const userInputValue = e.target.value;
    setUserValue(userInputValue)
    dispatch(setBookingSearchUserInput(userInputValue));
  }

  const navigateToDetails = (e:any) => {
    e.preventDefault()
    dispatch(setSearchType({type: 'BOOKING'}));
    dispatch(fetchBookingDataBySearch(true))
  };

  return (
    <Form floatingLabel testId="searchForm" onSubmit={navigateToDetails}>
        <FormField
            labelForId="test-form-input"
            labelText="Search for Booking"
            type="input"
            testId='testInputText'
        >
            <div className='form-field-container'>
              <Input
                  className="booking-id-search"
                  value={bookingValue}
                  id="test-booking-id-input"
                  testId='testBookingIdField'
                  placeholder="EMG Booking Id"
                  noBackground
                  onChange={handleChangeId}
              />
              <Input
                className='user-input-search'
                addonIcon="semantic-search"
                value={userValue}
                id="test-booking-userInput-input"
                testId='testBookingInputField'
                placeholder="Email or Last Name"
                noBackground
                onChange={handleChangeUserInput}
                addonElement="button"
                addonClickHandler={(e) => navigateToDetails(e)}
                addonType="submit"
              />
            </div>
        </FormField>
      { customer.error === 'BOOKING_NOT_FOUND' && <FormFieldLabel testId="noBooking" className='Error'>Booking not found for this ID and Email/Name.</FormFieldLabel>}
      { customer.error === 'FORBIDDEN' && (
        <FormFieldLabel testId="forbiddenLabel" className='Error'>
          Forbidden access.
        </FormFieldLabel>
      )}
      { customer.id && customer.search.type === 'BOOKING' && <Navigate to={`/customers/${customer.id}?tab=booking-history&bookingId=${customer.search.identifier.bookingId}&userInput=${customer.search.identifier.userInput}`} replace={true}/>}
    </Form>
  )
}
