import React, { useState } from 'react';
import { Button, FormFieldLabel, InfoIcon, Modal, Table } from '@vwfs-bronson/bronson-react';
import './style.css';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchCustomerDataById, resetIdVerification, selectCustomer } from "../../../redux/slices/customer-slice";
import DetailComponent from "../../base/detail-component";
import { IdVerificationSession } from '../../../models/customer-data.interface';

import { formatter } from '../../../utils/time-utils';
import { getSessionRejectReasonDetails, getSessionStateDetails } from './session-state-mapper';
import { toLower, upperFirst } from 'lodash';

function stylizeStatusString(statusString: string) {
  return statusString.split('_').map(word => upperFirst(toLower(word))).join(' ');
}

export default function IdVerificationDataView() {
  const customer = useAppSelector(selectCustomer());
  const dispatch = useAppDispatch();
  const [isResetIdVerificationModalOpen, setIsResetIdVerificationModalOpen] = useState(false);
  const handleResetIdVerificationClick = () => {
    setIsResetIdVerificationModalOpen(true);
  };

  const handleConfirmResetIdVerification = async () => {
    setIsResetIdVerificationModalOpen(false);
    dispatch(resetIdVerification({ id: customer.customer?.id })).then((res) => {
      if ('fulfilled' === res.meta.requestStatus) {
        dispatch(fetchCustomerDataById({ id: customer.customer?.id }));
      }
    });
  };

  const handleCancelResetIdVerification = () => {
    setIsResetIdVerificationModalOpen(false);
  };

  function renderIdVerificationHistoryItem(cd: IdVerificationSession) {

    const stateDetails = getSessionStateDetails(cd.result);
    const rejectionDetails = getSessionRejectReasonDetails(cd.resultReason);

    const result = cd.result && stylizeStatusString(cd.result);
    const resultReason = cd.resultReason && stylizeStatusString(cd.resultReason);

    return (
      <Table.Tr key={cd.id} className='table-row'>
        <Table.Td>{cd.id}</Table.Td>
        <Table.Td>{cd.startDateTime ? formatter.format(new Date(cd.startDateTime)) : '-'}</Table.Td>
        <Table.Td>{cd.endDateTime ? formatter.format(new Date(cd.endDateTime)) : '-'}</Table.Td>
        <Table.Td>{cd.resultDateTime ? formatter.format(new Date(cd.resultDateTime)) : '-'}</Table.Td>
        <Table.Td>{result || '-'}{stateDetails && <InfoIcon className="semantic-info" style={{ marginLeft: '10px' }} testId="infoIconMspData">{stateDetails}</InfoIcon>}</Table.Td>
        <Table.Td>{resultReason || '-'}{rejectionDetails && <InfoIcon className="semantic-info" style={{ marginLeft: '10px' }} testId="infoIconMspData">{rejectionDetails}</InfoIcon>}</Table.Td>
      </Table.Tr>
    );
  }

  return (
    <>
      <div>
        <div className='id-verification-details'>
          <DetailComponent id='idVerificationStatus' label='Id Verification Status' value={customer.customer?.idVerificationStatus} />
          <DetailComponent id='firstName' label='First Name' value={customer.customer?.idDocument?.firstName} />
          <DetailComponent id='lastName' label='Last Name' value={customer.customer?.idDocument?.lastName} />
          <DetailComponent id='dateOfBirth' label='Date of Birth' value={customer.customer?.idDocument?.dateOfBirth} />
          <DetailComponent id='documentType' label='Document Type' value={customer.customer?.idDocument?.documentType} />
          <DetailComponent id='status' label='Status' value={customer.customer?.idDocument?.status} />
          <DetailComponent id='documentNumber' label='Document Number' value={customer.customer?.idDocument?.documentNumber} />
          <DetailComponent id='nationality' label='Nationality' value={customer.customer?.idDocument?.nationality} />
          <DetailComponent id='issuingCountry' label='Issuing Country' value={customer.customer?.idDocument?.issuingCountry} />
          <DetailComponent id='issuingAuthority' label='Issuing Authority' value={customer.customer?.idDocument?.issuingAuthority} />
          <DetailComponent id='dateOfIssue' label='Date of Issue' value={customer.customer?.idDocument?.dateOfIssue} />
          <DetailComponent id='validUntil' label='Valid until' value={customer.customer?.idDocument?.validUntil} />
          <DetailComponent id='verificationDateTime' label='Verification DateTime' value={customer.customer?.idDocument?.verificationDateTime} />
          <DetailComponent id='createdAt' label='Created At' value={customer.customer?.idDocument?.createdAt} />
          <DetailComponent id='updatedAt' label='Updated At' value={customer.customer?.idDocument?.updatedAt} />
        </div>
        <div className='id-verification-details'>
          <DetailComponent id='dlVerificationStatus' label='Dl Verification Status' value={customer.customer?.dlVerificationStatus} />
          <DetailComponent id='status' label='Status' value={customer.customer?.drivingLicense?.status} />
          <DetailComponent id='documentNumber' label='Document Number' value={customer.customer?.drivingLicense?.documentNumber} />
          <DetailComponent id='issuingCountry' label='Issuing Country' value={customer.customer?.drivingLicense?.issuingCountry} />
          <DetailComponent id='issuingAuthority' label='Issuing Authority' value={customer.customer?.drivingLicense?.issuingAuthority} />
          <DetailComponent id='dateOfIssue' label='Date of Issue' value={customer.customer?.drivingLicense?.dateOfIssue} />
          <DetailComponent id='validUntil' label='Valid until' value={customer.customer?.drivingLicense?.validUntil} />
          <DetailComponent id='verificationDateTime' label='Verification DateTime' value={customer.customer?.drivingLicense?.verificationDateTime} />
          <DetailComponent id='dateOfIssueClassB' label='Date of Issue Class B' value={customer.customer?.drivingLicense?.dateOfIssueClassB} />
          <DetailComponent id='createdAt' label='Created At' value={customer.customer?.drivingLicense?.createdAt} />
          <DetailComponent id='updatedAt' label='Updated At' value={customer.customer?.drivingLicense?.updatedAt} />
        </div>
        <div className='id-verification-history-details' data-testid="id-verification-history-table">
          {customer.customer?.idVerificationSessions && customer.customer?.idVerificationSessions?.length > 0 && (
            <>
              <FormFieldLabel testId="id-verification-history-label"><b>Id Verification History</b></FormFieldLabel>
              <Table colored={true}>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>ID</Table.Th>
                    <Table.Th>Session Start Time</Table.Th>
                    <Table.Th>Session End Time</Table.Th>
                    <Table.Th>Result End Time</Table.Th>
                    <Table.Th>Status</Table.Th>
                    <Table.Th>Rejection Reason</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{customer.customer?.idVerificationSessions?.map((session) => renderIdVerificationHistoryItem(session))}</Table.Tbody>
              </Table>
            </>
          )}

          {(!customer.customer?.idVerificationSessions || customer.customer?.idVerificationSessions?.length === 0) && (<FormFieldLabel testId="no-id-verification-history-label">No Id Verification History</FormFieldLabel>)}
        </div>
        <div>
          <Button
            className='blue-button'
            data-testid='resetIdVerificationButtonTestID'
            onClick={handleResetIdVerificationClick}
            disabled={customer.customer?.accountStatus !== "IN_PROGRESS" ||
              ((!customer.customer?.dlVerificationStatus || customer.customer?.dlVerificationStatus === 'SKIPPED') &&
                (!customer.customer?.idVerificationStatus || customer.customer?.idVerificationStatus === 'SKIPPED'))}
            title={((customer.customer?.accountStatus !== "IN_PROGRESS" ||
              ((!customer.customer?.dlVerificationStatus || customer.customer?.dlVerificationStatus === 'SKIPPED') &&
                (!customer.customer?.idVerificationStatus || customer.customer?.idVerificationStatus === 'SKIPPED'))) ? 'Only possible for customer with state IN_PROGRESS and existing driving license verification or id verification status.' : '')}>
            RESET ID VERIFICATION
          </Button>

        </div>
      </div>
      <Modal
        shown={isResetIdVerificationModalOpen}
        onClose={handleCancelResetIdVerification}
        onConfirm={handleConfirmResetIdVerification}
        onCancel={handleCancelResetIdVerification}
        buttonCancelLabel="Cancel"
        buttonCancelText="Cancel"
        buttonConfirmLabel="Confirm"
        buttonConfirmText="Confirm"
        title="Confirm Reset ID Verification"
      >
        <p>Are you sure you want to reset the id verification?</p>
      </Modal>
    </>
  );
}
