import AuthBifrostConfig from "../configuration/auth-bifrost-config";
import AuthEmobgConfig from "../configuration/auth-emobg-config";
import Cookies from "js-cookie";

export function clearAuthParams(): void {
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname
    );
}

export function getAuthConfig() {
    const provider = Cookies.get('backoffice');
    if (provider !== 'emobg' && provider !== 'bifrost') {
        return null;
    }
    return provider === 'emobg' ? AuthEmobgConfig : AuthBifrostConfig
}

export function setEmobgAuthConfig() {
    Cookies.set('backoffice', 'emobg', { secure: true, sameSite: 'none' });
}

export function setBifrostAuthConfig() {
    Cookies.set('backoffice', 'bifrost', { secure: true, sameSite: 'none' });
}

export function removeAuthConfig() {
    Cookies.remove('backoffice');
}

export function isValidAuthConfig(): boolean {
    const provider = Cookies.get('backoffice');
    return provider === 'emobg' || provider === 'bifrost'
}
