export const RejectionReasonsDetails = {
    ID_BLURRY: "Document is blurry and mandatory data cannot be read due to the blur.",
    ID_GLARE: "Document has glare and mandatory data cannot be read due to the glare.",
    ID_DARKNESS: "Pictures of the document are dark and it is not possible to read the mandatory data or verify the authenticity of the document.",
    ID_DATA_COVERED: "Mandatory data is covered by the user while taking the picture.",
    ID_PERSPECTIVE: "Document is positioned at such an angle that mandatory data cannot be read or document cannot be verified.",
    ID_DATA: "Mandatory data cannot be read on the document.",
    ID_DATA_OTHER: "Any other reason due to which mandatory data cannot be read.",
    ID_NOT_SUPPORTED: "Document used during the identification is not supported for the customer's use case.",
    ID_EXPIRED: "Document used during the identification is expired.",
    ID_WRONG_SIDE: "Wrong side of the document is scanned during the process.",
    ID_OUTWORN: "Document is worn out. Either data cannot be read out or the document cannot be verified.",
    ID_HAS_STICKER: "Document has such stickers which are not acceptable and the document used is considered as damaged document.",
    ID_WRITTEN_ON: "Document has text written over it which makes the document not readable or not verifiable. If the sticker is legit one and added by the authorities while issuing the document then the document will be acceptable and not cancelled due to this reason.",
    ID_BROKEN: "Document used during the identification is broken.",
    ID_DAMAGED: "Document used during identification is a damaged document.",
    ID_DAMAGED_OTHER: "Any other reason for a damaged document.",
    ID_SECURITY_FEATURE_NOT_VISIBLE_NOT_FRAUD: "Security features of the document are not visible because user did not move the document correctly.",
    ID_SECURITY_FEATURE_VIDEO_SHORT: "Security feature video is too short to detect if there are holograms in the document.",
    ID_SECURITY_FEATURE_VIDEO_CANNOT_BE_PLAYED: "Security feature video cannot be played for the agent to review holograms.",
    ID_SECURITY_FEATURE_OTHER: "Any other issues with the security feature video.",
    ID_SECOND_DOCUMENT: "If two documents are required for the identification process, the user needs to photograph two different documents (i.e. ID + Driver's license) - If the second required ID document is not available, the ident will be cancelled.",
    ID_SECOND_DOCUMENT_BAD_PHOTO_QUALITY: "Photo quality of the additional document in the process is not acceptable.",
    ID_SECOND_DOCUMENT_DAMAGED: "Additional document used in the identification process is severely outworn, written or drawn on, ripped or broken.",
    ID_SECOND_DOCUMENT_EXPIRED: "Additional document used in the identification process is an expired document.",
    ID_SECOND_DOCUMENT_OTHER: "Any other issues with the additional document used in the identification process.",
    ID_NEED_ADDITIONAL_DOCUMENT: "Additional document like Driver's License is missing in the identification process but it was required.",
    ID_OTHER: "Any other issues with the document used in the identification process.",
    USER_INVOICE_MISSING: "Customer needs proof of address from the user as the additional document but user did not provide it in the identification process.",
    USER_OBSCURED: "User has covered the face during the face comparison process unintentionally like wearing the face mask.",
    SELFIE_BLURRY: "Selfie taken by the user is blurry and cannot be used to compare the face with the identification document.",
    SELFIE_GLARE: "Photo of the user on the ID document has glares and selfie cannot be compared with it.",
    SELFIE_DARKNESS: "Selfie taken by the user is too dark to compare the face of the person with the photo on the identification document.",
    SELFIE_PERSPECTIVE: "Selfie taken by the user is on such an angle that it is not possible to compare it with the photo on the identification document.",
    SELFIE_OTHER: "Any other issues with the selfie which restrict ident specialist to compare the selfie of the user with the photo on the identification document.",
    IDENT_CANNOT_BE_COMPLETED: "Due to a technical reason, ident specialist cannot finish the identity verification process.",
    IDENT_DISPLAY_ERROR: "Due to a technical reason, ident specialist cannot see the data submitted by the user in the identification process.",
    IDENT_OTHER: "Any other reason due to which the identification process cannot be completed by the ident specialist.",
    TSP_WRONG_CONFIRMATION_TOKEN: "A wrong signing code was entered by the user during the signing step.",
    TSP_SIGNING_FAILED: "The signing process failed due to any technical error.",
    TSP_CERTIFICATE_EXPIRED: "Signing certificates are valid for an hour. The final signing step took more than an hour from the time of certificate generation.",
    ID_SECURITY_FEATURE_VIDEO_FILE_MISSING: "If enabled, the system automatically cancels an ident when the 'Security Features' (Hologram) video is not saved due to any technical error, and is hence missing from the final result (zip) file.",
    WARNING_SOCIAL_ENGINEERING: "The user has been a victim of a social engineering attempt. Important: The user is not doing a fraud attempt himself but has been tricked into performing an identity verification.",
    WARNING_ID_MANIPULATION: "The ID document has been manipulated (e.g. fields have been changed, photo has been replaced).",
    FACE_COMPARISON_UNMATCHED: "Selfie taken during the process is not of the same person who is on the document.",
    WARNING_FAKE_ID: "The ID document is a completely falsified document.",
    WARNING_PHOTO: "The person in the selfie is different from the person on the ID document.",
    WARNING_DESCRIPTION: "The description of the person (e.g. height, eye color, age) does not match the person.",
    WARNING_USER_UNDERAGE: "The user is below the required age set by the customer and is not allowed to avail the service.",
    WARNING_INCONSISTENT_DATA: "The data on the ID document is inconsistent (e.g. differences between visible zone and MRZ).",
    DOCUMENT_TAMPERED: "The document has been modified on the front side or back side or using a separate document for front side and back side.",
    WARNING_FAKED_MANIPULATED_ID: "Fields from document have been modified e.g. covered on purpose.",
    WARNING_FRAUD_OTHER: "Used when the fraud attempt does not fit to other categories but it is still a fraud for sure.",
    WARNING_WRONG_CHECKSUM: "A checksum on the ID document is wrong.",
    WARNING_IDENTITY_THEFT: "User has tried to steal someone's identity.",
    SECURITY_FEATURE_IDENTITY_FAKED: "Security features have been modified.",
    LIVENESS_DETECTION_FAILED: "Person has tried to perform a fraud attack against the liveness check, e.g. different person in selfie and liveness.",
    SECURITY_FEATURE_DIGITAL_IMAGE: "Person has tried to manipulate the security features of the document digitally.",
    FRONT_DOCUMENT_DIGITAL_IMAGE: "Person has tried to manipulate the front side of the document digitally.",
    BACK_DOCUMENT_DIGITAL_IMAGE: "Person has tried to manipulate the back side of the document digitally.",
    FACE_COMPARISON_DIGITAL_IMAGE: "Image of user has been modified digitally.",
    LIVENESS_LEFT_DIGITAL_IMAGE: "Liveness left image of the user has been modified digitally.",
    LIVENESS_RIGHT_DIGITAL_IMAGE: "Liveness right image of the user has been modified digitally.",
    USER_CANCELLATION_DOCUMENT_NOT_ACCEPTED: "User's ID document is not accepted for the verification process.",
    USER_CANCELLATION_DOCUMENT_EXPIRED: "User cannot continue the verification process due to an expired ID document.",
    USER_CANCELLATION_UNDERAGE: "User is underage and is not allowed to continue the verification process.",
    USER_CANCELLATION_CAMERA_ACCESS_DENIED: "User does not allow camera permission in the app.",
    USER_CANCELLATION_TERMS_DENIED: "User does not accept the terms and conditions.",
    USER_CANCELLATION_SELFIE_NOT_READY: "User is not ready for a selfie.",
    USER_CANCELLATION_APP_NOT_SCANNING: "User aborts as the app is not scanning the document.",
    USER_CANCELLATION_ESIGNING_REJECTED: "User does not accept the esigning request.",
    USER_CANCELLATION_ESIGNING_NAME_CONFIRMATION_REJECTED: "User's name is specified incorrectly (This applies only to the QES/Signing flow).",
    USER_CANCELLATION_INCORRECT_PHONE_NUMBER: "User aborts as the phone number is specified incorrectly.",
    USER_CANCELLATION_IDENTIFY_LATER: "User wants to identify later.",
    USER_CANCELLATION_USER_NOT_INTERESTED: "User is not interested in performing the identity verification.",
    USER_CANCELLATION_APP_NOT_RESPONDING: "User aborts because the app is not responding.",
    USER_CANCELLATION_PRIVACY_CONCERNS: "User aborts due to privacy concerns.",
    USER_CANCELLATION_DOCUMENT_NOT_AVAILABLE: "User does not have the ID document available and aborts.",
    APP_CANCELLATION_APPROVAL_PHRASE_RETRY_LIMIT_REACHED: "This is used in the QES/esign flow when the app is unable to retrieve the approval phrases from the backend after multiple retries.",
    APP_CANCELLATION_APPROVAL_PHRASE_NO_CONTRACT: "App encounters a technical error in the Approval Phrases step in QES/esign.",
    APP_CANCELLATION_APPROVAL_PHRASE_INSUFFICIENT_DOCUMENT_COUNT: "In the Approval Phrases step for QES/esign, the required number of documents to be signed are not received from backend.",
    APP_CANCELLATION_OTP_MATCH_LIMIT_REACHED: "In the OTP Authentication step, the user has reached the allowed limit for number of OTP entries and cannot try again.",
    APP_CANCELLATION_TSP_TECHNICAL_EXCEPTION: "App encounters a technical error from the TSP side. If this error state does not resolve after multiple automatic retries, the user cannot proceed further and the ident is aborted with this reason."
};

const SessionStateDetails = {
    REVIEW_PENDING: "The user has completed the identification process in the app and the Ident is pending review.",
    FRAUD_SUSPICION_PENDING: "Fraud suspicion detected via the system, forward to an agent for final check.",
    CANCELED: "The Ident has been cancelled by the agent due to an incorrectly performed ident by the user. For example, if the user has performed the ident in a very low lighting condition and the data of the document is not readable then the agent will cancel the ident since no conclusive decision can be made on the ident data.",
    FRAUD_SUSPICION_CONFIRMED: "The agent has a suspicion that the user tried to commit fraud. Please request more input via IDNow service desk.",
    SUCCESS: "The Ident verification has been performed without problems or changed data.",
    SUCCESS_DATA_CHANGED: "The Ident verification has been performed without problems, but data has been changed compared to the initially provided data."
}

export function getSessionStateDetails(sessionState: string) {
    return SessionStateDetails[sessionState];
}

export function getSessionRejectReasonDetails(rejectReason: string) {
    return RejectionReasonsDetails[rejectReason];
}