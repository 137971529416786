import React from 'react';
import './style.css';
import SearchCustomerView from '../search-customer-view';
import SearchBookingView from "../search-booking-view";

export default function SearchContainerView() {
    return (
      <div className="search-container-group">
        <div data-testid="searchContainer" className="flex-container1">
          <div data-testid="searchComponent" className="flex-child">
            <SearchCustomerView/>
          </div>
        </div>
        <div/>
        <div data-testid="searchContainer" className="flex-container2">
          <div data-testid="searchComponent" className="flex-child">
            <SearchBookingView/>
          </div>
        </div>
      </div>
    );
}
