import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import * as customerSupportApi from '../../apis/customer-support-api';
import ICustomerData, { ICustomerBooking, ICustomerBookingResponse } from '../../models/customer-data.interface';
import { RootState } from '../store';
import { CustomerState } from './customer-state';
import {customerSlice} from "./customer-slice";

// ## Fetch Customer Bookings

export const fetchCustomerBookings = createAsyncThunk<
    ICustomerBooking[],
    { id: string },
    { state: RootState }
>('customer/fetchCustomerBookings', async ({ id }, { dispatch, getState }) => {
    const result: ICustomerBookingResponse = await customerSupportApi.getCustomerBookings(id);
    return result.data.sort((e1, e2) => new Date(e1.offer.startDate).valueOf() - new Date(e2.offer.startDate).valueOf());
},
    {
        condition: (refresh, { getState }) => {
            // execute the function if it's explicitly requested
            if (refresh) {
                return true;
            }

            const { customer } = getState();
            customer.error = null;
            return customer.bookings.status === 'idle'; // execute the fetch function only when the status is idle
        },
    }
);

export const fetchBookingDataBySearch = createAsyncThunk<
  ICustomerBooking,
  boolean | undefined,
  { state: RootState }
>('customer/fetchBookingDataBySearch', async (_, { dispatch, getState }) => {
      const bookingId = getState().customer.search.identifier.bookingId;
      const userInput = getState().customer.search.identifier.userInput;

      if (getState().customer.search.type === 'BOOKING') {
          const response = await customerSupportApi.getBookingByIdAndUserInput(bookingId, userInput)
          return response;
      }

  },
  {
      condition: (refresh, { getState }) => {
          // execute the function if it's explicitly requested
          if (refresh) {
              return true;
          }

          const { customer } = getState();
          customer.error = null;
          return customer.status === 'idle'; // execute the fetch function only when the status is idle
      },
  }
);


// ## Cancel Customer Booking
const cancelThunkHandler = async ({ customerId, bookingId }, { rejectWithValue }) => {
    try {
        return (await customerSupportApi.cancelCustomerBooking(customerId, bookingId)).data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
}

const cancelThunkOptions = {
    condition: (refresh, { getState }) => {
        // execute the function if it's explicitly requested
        if (refresh) {
            return true;
        }

        const { customer } = getState();
        customer.error = null;
        return customer.bookings.status === 'idle'; // execute the fetch function only when the status is idle
    },
}

export const cancelCustomerBooking = createAsyncThunk<ICustomerBooking, { customerId: string, bookingId: string }, { state: RootState }>(
    'customer/cancelCustomerBooking',
    cancelThunkHandler, cancelThunkOptions
);

export function addBookingCases(builder: ActionReducerMapBuilder<CustomerState>) {
    builder
        .addCase(fetchCustomerBookings.pending, (state, action) => {
            state.bookings.status = 'loading';
            state.error = null;
            state.errorDialog = null;
        })
        .addCase(fetchCustomerBookings.fulfilled, (state, action) => {
            state.error = null;
            state.errorDialog = null;

            // sort bookings into two piles
            const sorted = action.payload.reduce((acc, val) => {
                if (val.status === 'POSTPAID_CANCELED' || val.status === 'PREPAID_CANCELLED') {
                    acc.canceled.push(val);
                } else {
                    acc.other.push(val);
                }

                return acc;
            }, { canceled: [], other: [] });

            state.bookings.data = [...sorted.other, ...sorted.canceled];
            state.bookings.status = 'succeeded';
        })
        .addCase(fetchCustomerBookings.rejected, (state, action) => {
            state.error = 'An error occurred: Could not fetch the bookings of the customer.';
            state.errorDialog = {
                code: 'UNABLE_TO_FETCH_BOOKINGS',
                title: 'Unable to fetch Bookings',
                message: 'An error occurred: Could not fetch the bookings of the customer.'
            }
            state.id = undefined;
            state.bookings.status = 'failed';
        })
        .addCase(cancelCustomerBooking.pending, (state, action) => {
            state.bookings.status = 'loading';
            state.error = null;
            state.errorDialog = null;
        })
        .addCase(cancelCustomerBooking.fulfilled, (state, action) => {
            state.error = null;
            state.errorDialog = null;

            state.bookings.status = 'succeeded';

            const bookingId = action.payload.id;
            const bookingIndex = state.bookings.data.findIndex(booking => booking.id === bookingId);

            state.bookings.data[bookingIndex] = { ...action.payload };
        })
        .addCase(cancelCustomerBooking.rejected, (state, action) => {
            state.error = 'An error occurred: Could not cancel the customer booking.';
            state.errorDialog = {
                code: 'UNABLE_TO_CANCEL_BOOKING',
                title: 'Unable to cancel Booking',
                message: 'An error occurred: Could not cancel the customer booking.'
            }
            state.id = undefined;
            state.bookings.status = 'failed';
        })

      .addCase(fetchBookingDataBySearch.pending, (state, action) => {
          state.status = 'loading';
          state.error = null;
          state.errorDialog = null;
      })
      .addCase(fetchBookingDataBySearch.fulfilled, (state, action) => {
          //state.customer = state.customer = action.payload[0];
          if (!action.payload) {
              state.status = 'failed';
              state.error = 'BOOKING_NOT_FOUND';
              state.id = undefined;
          } else {
              state.status = 'succeeded';
              state.error = null;
              state.errorDialog = null;
              state.bookings.data = [action.payload];
              if (action.payload.customerId){
                  state.id = action.payload.customerId;
                  //state.customer = {id: '29841b11-c0b5-46e0-8784-33285501e5fd'};
              } else {
                  state.id = 'guest';
                  state.customer = {id: 'guest'};
              }
          }
      })
      .addCase(fetchBookingDataBySearch.rejected, (state, action) => {
          if (action.error.message === 'FORBIDDEN') {
              state.error = 'FORBIDDEN';
          } else {
              state.error = action.error.message;
          }
          state.status = 'failed';
          state.id = undefined;
      })

}

export const bookingReducers = {
}
