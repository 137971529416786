import { User } from 'oidc-client-ts';
import ICustomerData, {
  ICancelCustomerBookingResponse,
  ICustomerAudit, ICustomerBooking,
  ICustomerBookingResponse,
  ICustomerTripsResponse,
  IdVerificationSession
} from "../../models/customer-data.interface";
import { getAuthConfig } from "../../utils/auth-utils";

function getAccessToken(): string {
  const authConfig = getAuthConfig();
  const oidcStorage = sessionStorage.getItem(`oidc.user:${authConfig.authority}:${authConfig.client_id}`)
  if (!oidcStorage) {
    return 'No token';
  }

  return User.fromStorageString(oidcStorage)?.access_token;
}

function headers(): object {
  if (process.env.REACT_APP_STAGE === 'local') {
    return {
      Authorization: `Bearer ${getAccessToken()}`,
      'user-agent-email': 'admin@vwfs.io',
    }
  } else {
    return {
      Authorization: `Bearer ${getAccessToken()}`,
    }

  }
}

export async function getCustomerByEmail(email: string): Promise<ICustomerData> {
  const encodedEmail = encodeURIComponent(email);
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers?email=${encodedEmail}`, {
    headers: { ...headers() }
  }
  );

  if (response.status === 403) {
    throw new Error('FORBIDDEN')
  }

  const responseBody = await response.json();
  return responseBody.data;
}

export async function getCustomerById(id: string): Promise<ICustomerData> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}`, {
      headers: { ...headers() },
  }
  );
  const responseBody = await response.json();
  return responseBody.data;
}

export async function getCustomerIdVerificationSessions(id: string): Promise<IdVerificationSession[]> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/id-verification-sessions`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    }
  }
  );
  const responseBody = await response.json();

  return responseBody.data;
}

export async function getCustomerAuditTrail(id: string, pageSize: number, cursor?: string): Promise<ICustomerAudit> {
  const cursorParam = cursor ? '&cursor=' + cursor : '';
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/audit-trail?pageSize=${pageSize}${cursorParam}`, {
      headers: { ...headers() }
  }
  );
  return await response.json();
}

export async function markCustomerForDeletion(id: string): Promise<string> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/mark-for-deletion`, {
      headers: { ...headers() },
      method: "POST"
  },
  );
  if (response?.status === 204) {
    return await response.text();
  } else {
    return (await response.json())?.errors[0]?.code as string | 'UNKNOWN_ERROR';
  }
}

export async function unmarkCustomerForDeletion(id: string): Promise<string> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/unmark-for-deletion`, {
      headers: { ...headers() },
      method: "POST"
  },
  );
  return await response.text();
}

export async function resetIdVerification(id: string): Promise<string> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/reset-id-verification`, {
      headers: { ...headers() },
    method: "POST"
  },
  );
  return await response.text();
}

export async function suspendCustomer(id: string): Promise<string> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/suspend`, {
      headers: { ...headers() },
    method: "POST"
  },
  );
  if (response.status === 204) {
    return await response.text();
  } else {
    const json = await response.json();
    return json.errors[0].code as string | 'UNKNOWN_ERROR';
  }
}

export async function unsuspendCustomer(id: string): Promise<string> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/unsuspend`, {
      headers: { ...headers() },
    method: "POST"
  },
  );
  return await response.text();
}

export async function getCustomerBookings(id: string): Promise<ICustomerBookingResponse> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/bookings`, {
      headers: { ...headers() }
  }
  );
  return await response.json();
}

export async function getCustomerTrips(id: string): Promise<ICustomerTripsResponse> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${id}/trips`, {
      headers: { ...headers() }
  }
  );
  return await response.json();
}

export async function cancelCustomerBooking(customerId: string, bookingId: string): Promise<ICancelCustomerBookingResponse> {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/customers/${customerId}/bookings/${bookingId}/cancel`, {
      headers: { ...headers() },
    method: "POST"
  }
  );

  if (response?.status === 200) {
    return await response.json();
  } else {
    throw Error((await response.json())?.errors[0]?.code as string | 'UNKNOWN_ERROR');
  }
}

export async function getBookingByIdAndUserInput(bookingId: string, userInput: string): Promise<ICustomerBooking> {
  const encodedBookingId = encodeURIComponent(bookingId);
  const encodedUserinput = encodeURIComponent(userInput);
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/bookings?externalBookingId=${encodedBookingId}&userInput=${encodedUserinput}`, {
      headers: { ...headers() }
    }
  );

  if (response.status === 403) {
    throw new Error('FORBIDDEN')
  }

  const responseBody = await response.json();
  return responseBody.data;
}

